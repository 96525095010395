import React from "react"
import MagnifyIcon from "mdi-react/MagnifyIcon"
import SendIcon from "mdi-react/SendIcon"
import io from "socket.io-client"

import Loader from "../components/loader/loader"
import SEO from "../components/seo"
import { axios } from "../utils/utils"
import {
  mapIconNameToIconSource,
  BASE_URL,
  TOAST_TIMER,
  TIMESTAMP_RERENDER_TIMEOUT,
} from "../utils/utils"
import UserTab from "./../components/user-tab/userTab"
import Message from "./../components/message/message"

import "./chat.scss"
import Toast from "./../components/toast/toast"

class Chat extends React.Component {
  constructor() {
    super()
    this.state = {
      currentUserShowing: null,
      userNameSearch: "",
      chatBox: "",
      users: [],
      messages: [],
      connectedToSocket: false,
      getMesssageConnectionName: "",
      postMesssageConnectionName: "",
      socket: {},
      loading: true,
      showGettingMessagesErrorToast: false,
      showSendingMessagesErrorToast: false,
      showNewMessageToast: false,
      newMessageWithUserDetail: {},
      lastUpdated: new Date(),
      interval: null,
    }
    if (typeof document !== `undefined`) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden"
    }
  }

  componentDidMount() {
    var interval = setInterval(
      () => this.setState({ lastUpdated: new Date() }),
      TIMESTAMP_RERENDER_TIMEOUT
    )
    this.setState({ interval })
    this.goToBottom()
    this.getAllUsersAndGroups()
  }

  componentWillReceiveProps = props => {
    this.connectToSocket(props.user, this.state.users, this.state.groups)
  }

  getAllUsersAndGroups = () => {
    var promises = []
    promises.push(axios.get("/users"))
    promises.push(axios.get("/groups"))

    Promise.all(promises).then(responses => {
      var users = responses[0].data
      var groups = responses[1].data
      var usersAndGroups = groups.concat(users)
      this.setState(
        { users: this.orderUsersByLastMessage(usersAndGroups), currentUserShowing: users[0]?._id },
        () => {
          this.connectToSocket(this.props.user, usersAndGroups, groups)
        }
      )
    })
  }

  emitUserSelectionChange = () => {
    if (this.state.socket) {
      this.state.socket.emit(
        this.state.getMesssageConnectionName,
        this.state.currentUserShowing,
        this.isCurrentUserAGroup()
      )
    } else {
      this.showGettingMessagesErrorToast('this.state.socket is null/undefined')
    }
  }

  setMessageGetterSocket = () => {
    this.state.socket.on(this.state.getMesssageConnectionName, messages => {
      if (messages.error) return this.showGettingMessagesErrorToast(messages.error + ' -- setMessageGetterSocket')
      this.setState({ messages, loading: false }, () => {
        this.goToBottom()
      })
    })
  }

  getCurrentShowingUser = () => {
    if (this.state.users) 
      return this.state.users.find(user => user._id === this.state.currentUserShowing)
    else return null
  }

  isCurrentUserAGroup = () => {
    const user = this.getCurrentShowingUser();
    if (user != null) {
      return user.isGroup
    } else {
      return false
    }
  }

  setGroupConnectionSockets = groupConnectionNames => {
    groupConnectionNames.forEach(connectioName =>
      this.setMessageSetterSocket(connectioName)
    )
  }

  orderUsersByLastMessage = users => {
    return users.sort((a, b) => {
      var aDate = new Date(a.lastMessage.timestamp)
      var bDate = new Date(b.lastMessage.timestamp)
      return bDate - aDate
    })
  }

  setMessageSetterSocket = connectionName => {
    this.state.socket.on(connectionName, message => {
      if (message.error) return this.showSendingMessagesErrorToast(message.error)
      var messages = [...this.state.messages]
      var users = [...this.state.users]
      var indexOfUserTabToUpdate = users.findIndex(
        user => user._id === message.from || user._id === message.to
      )
      users[indexOfUserTabToUpdate].lastMessage = message
      users = this.orderUsersByLastMessage(users)
      if (
        message.to === this.state.currentUserShowing ||
        (message.from === this.state.currentUserShowing && !message.isGroup)
      ) {
        messages.push(message)
      } else {
        this.showNewMessageToast(message)
      }
      this.setState(
        {
          messages,
          users
        },
        () => {
          this.scrollToBottom()
        }
      )
    })
  }

  connectToSocket = (user, users, groups) => {
    if (!this.state.connectedToSocket && user && users.length > 0) {
      const socket = io.connect(BASE_URL, {
        query: {
          token: user.token,
        },
      })
      var groupConnectionNames = groups.map(group => group._id)
      this.setState(
        {
          connectedToSocket: true,
          getMesssageConnectionName: `${user._id}/getMessages`,
          postMesssageConnectionName: `${user._id}/postMessages`,
          socket,
        },
        () => {
          this.setState({ socket })
          this.setMessageGetterSocket()
          this.setMessageSetterSocket(this.state.postMesssageConnectionName)
          this.setGroupConnectionSockets(groupConnectionNames)
          this.emitUserSelectionChange()
        }
      )
    }
  }

  componentWillUnmount() {
    if (typeof document !== `undefined`) {
      document.getElementsByTagName("html")[0].style.overflow = "auto"
    }
    clearInterval(this.state.interval)
  }

  tabClick = userId => {
    if (this.state.currentUserShowing !== userId) {
      this.setState({ currentUserShowing: userId, loading: true }, () => {
        this.emitUserSelectionChange()
        this.goToBottom()
        this.props.userTabClick()
      })
    }
  }

  onUserNameSearch = e => {
    this.setState({
      userNameSearch: e.target.value,
    })
  }

  onChatBoxType = e => {
    this.setState({ chatBox: e.target.value })
  }

  onChatBoxSubmit = e => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
    if (this.state.chatBox.length > 0) {
      var message = {
        text: this.state.chatBox,
        from: this.props.user._id,
        to: this.state.currentUserShowing,
        isGroup: this.isCurrentUserAGroup(),
      }

      this.state.socket.emit(this.state.postMesssageConnectionName, message)
      this.setState({ chatBox: "" })
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" })
  }

  goToBottom = () => {
    this.messagesEnd.scrollIntoView()
  }

  toastTimer = toastName => {
    return () => {
      setTimeout(() => {
        this.setState({ [toastName]: false })
      }, TOAST_TIMER)
    }
  }

  showSendingMessagesErrorToast = (error) => {
    console.log("@@ error sending message", error)
    this.setState(
      {
        showSendingMessagesErrorToast: true,
      },
      this.toastTimer("showSendingMessagesErrorToast")
    )
  }

  showGettingMessagesErrorToast = (error) => {
    console.log("@@ error getting message", error)
    this.setState(
      {
        showGettingMessagesErrorToast: true,
      },
      this.toastTimer("showGettingMessagesErrorToast")
    )
  }

  showNewMessageToast = newMessage => {
    var newMessageUser
    if (newMessage.isGroup) {
      newMessageUser = this.state.users.find(user => user._id === newMessage.to)
    } else {
      newMessageUser = this.state.users.find(
        user => user._id === newMessage.from
      )
    }

    this.setState(
      {
        showNewMessageToast: true,
        newMessageWithUserDetail: {
          name: newMessageUser.name,
          text: newMessage.text,
          icon: newMessageUser.icon,
          letter: newMessageUser.letter,
          iconBackground: newMessageUser.iconBackground,
          _id: newMessageUser._id,
        },
      },
      this.toastTimer("showNewMessageToast")
    )
  }

  renderUserTab = () => {
    return (
      this.state.users &&
      this.state.users
        .filter(
          user =>
            ~user.name
              .toLowerCase()
              .indexOf(this.state.userNameSearch.toLowerCase())
        )
        .map((user) => (
          <UserTab
            onClick={() => this.tabClick(user._id)}
            open={user._id === this.state.currentUserShowing}
            name={user.name}
            lastMessage={user.lastMessage.text || `Say hello to ${user.name}`}
            icon={mapIconNameToIconSource[user.icon]}
            letter={user.letter}
            key={user.name}
            isGroup={user.isGroup}
            members={user.members || []}
            iconBackground={user.iconBackground}
          />
        ))
    )
  }

  render() {
    return (
      <>
        <div className="chat-page">
          <SEO title="Home" keywords={["gatsby", "application", "react"]} />
          <Toast show={this.state.showSendingMessagesErrorToast} error>
            Error sending messages
          </Toast>
          <Toast show={this.state.showGettingMessagesErrorToast} error>
            Error getting messages
          </Toast>
          <Toast
            show={this.state.showNewMessageToast}
            icon={
              mapIconNameToIconSource[this.state.newMessageWithUserDetail.icon]
            }
            letter={this.state.newMessageWithUserDetail.letter}
            iconBackground={this.state.newMessageWithUserDetail.iconBackground}
            onClick={() =>
              this.tabClick(this.state.newMessageWithUserDetail._id)
            }
          >
            {`${this.state.newMessageWithUserDetail.name}: "${this.state.newMessageWithUserDetail.text}"`}
          </Toast>
          <div
            className={`side-panel custom-scroll ${
              this.props.userTabShowing ? "on" : ""
            }`}
          >
            <div className="search-box">
              <MagnifyIcon />
              <input
                type="text"
                placeholder="Search username"
                value={this.state.userNameSearch}
                onChange={this.onUserNameSearch}
              />
            </div>
            <div className="users">{this.renderUserTab()}</div>
          </div>
          <div className="main-panel custom-scroll">
            <div className="message-panel">
              {this.state.loading ? (
                <Loader className="loader" />
              ) : (
                this.state.messages.map(message => {
                  var user
                  if (message.from === this.props.user._id) {
                    user = this.props.user
                  } else {
                    user = this.state.users.find(
                      user => user._id === message.from
                    )
                  }
                  return (
                    <Message
                      {...message}
                      icon={mapIconNameToIconSource[user.icon]}
                      iconBackground={user.iconBackground}
                      lastUpdated={this.state.lastUpdated}
                      isMyMessage={
                        this.props.user && this.props.user._id === message.from
                      }
                    />
                  )
                })
              )}
              <div className="bottom" ref={elem => (this.messagesEnd = elem)} />
            </div>
            <form className="chat-box" onSubmit={this.onChatBoxSubmit}>
              <input
                type="text"
                placeholder="Type in your message here..."
                onChange={this.onChatBoxType}
                value={this.state.chatBox}
              />
              <button className="submit" type="submit">
                <SendIcon />
              </button>
            </form>
          </div>
        </div>
      </>
    )
  }
}

export default Chat
