import React from "react"

import UserIcon from "./../user-icon/userIcon"

import { timeAgo } from "../../utils/utils"

import "./message.scss"

var Message = props => (
  <div
    className={`message-component ${props.className || ""} ${
      props.isMyMessage ? "myMessage" : ""
    }`}
  >
    <div className="message-wrap">
      <UserIcon
        className="user-icon"
        icon={props.icon}
        iconBackground={props.iconBackground}
      />
      <p className="message">{props.text}</p>
    </div>
    <p className="time">{timeAgo(props.timestamp, props.lastUpdated)}</p>
  </div>
)

export default Message
