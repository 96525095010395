import React from "react"

import "./userTab.scss"
import UserIcon from "./../user-icon/userIcon"

import { mapIconNameToIconSource } from "../../utils/utils"

var UserTab = props => (
  <div
    className={`user-tab-component ${props.className || ""} ${
      props.open ? "open" : "closed"
    } ${props.isGroup ? "group" : "user"}`}
    onClick={props.onClick}
  >
    <div className="body">
      <div className="left">
        <UserIcon
          icon={props.icon}
          iconBackground={props.iconBackground}
          letter={props.letter}
        />
      </div>
      <div className="right">
        <p className="user-name">{props.name}</p>
        <p className="last-message">{props.lastMessage}</p>
      </div>
    </div>
    <div className="bottom">
      <div className="members-title">Group members:</div>
      <div className="members">
        {props.members.map(member => (
          <UserIcon
            className="member-icon"
            icon={mapIconNameToIconSource[member.icon]}
            iconBackground={member.iconBackground}
          />
        ))}
      </div>
    </div>
  </div>
)

export default UserTab
